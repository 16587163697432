import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/internal/operators';
import { iEntityFilterParams, iEntityFilterAttribute, iEntityFilterGetherParams } from '../interfaces/entity-params';
import { UserService } from './user/user.service';
import { iUser, iPasswordChanges, iTrialUser, iPortal } from '../interfaces/user';
import { environment } from '../../../environments/environment';
import { iCoursesToCommunity } from '@coreinterfaces/community';

@Injectable()
export class CoreEntityService {

  defaultHttpOptions = {
    headers: new HttpHeaders({ 'Content-Type':  'application/json' })
  };

  REQUEST_PARAMS: iEntityFilterParams = {
    offset: 0,
    limit: 25,
    filter_by: [],
    search_by: [],
    order_by: []
  };

  constructor(protected _http: HttpClient,
              protected _userService: UserService) { }

  /**
   * Custom post request
   * @param reqURL
   * @param bodyString
   * @param requestOptions
   * @returns {any}
   */
  postRequest(reqURL: string, bodyString: string, requestOptions: any): Observable<any> {
    return this._http.post(`${environment.apiUrl}/${reqURL}`, bodyString, requestOptions).pipe(
      map((response: any) => {
        if (response.done) {
          return response.result;
        } else {
          throw new Error(response.error);
        }
      })
    );
  }

  getRequest(reqURL: string): Observable<any> {
    return this._http.get(`${environment.apiUrl}/${reqURL}`).pipe(
      map((response: any) => {
        if (response.done) {
          return response.result;
        } else {
          throw new Error(response.error);
        }
      })
    );
  }

  /*****************************************************  GETTERS  ****************************************************/

  getWithNoAuth(url: string): Observable<any> {
    return this.getRequest(url);
  }

  getWithToken(url: string): Observable<any> {
    const reqUrl = url + '?token=' + this._userService.token;
    return this.getRequest(reqUrl);
  }

  postWithNoAuth(url: string, data: any): Observable<any> {
    const bodyString = JSON.stringify(data);
    return this.postRequest(url, bodyString, this.defaultHttpOptions);
  }

  get(url: string, explicityRequest?: any, requestOptions?: any): Observable<any> {
    this.REQUEST_PARAMS.token = this._userService.token;
    let bodyString = JSON.stringify(this.REQUEST_PARAMS);
    if (explicityRequest) {
      explicityRequest.token = this._userService.token;
      bodyString = JSON.stringify(explicityRequest);
    }
    return this.postRequest(url, bodyString, requestOptions || this.defaultHttpOptions);
  }

  getEntity(entityType: string, explicityRequest?: iEntityFilterParams, requestOptions?: any): Observable<any> {
    const reqUrl = 'entity/' + entityType +  '/get';
    this.REQUEST_PARAMS.token = this._userService.token;
    let bodyString = JSON.stringify(this.REQUEST_PARAMS);
    if (explicityRequest) {
      explicityRequest.token = this._userService.token;
      bodyString = JSON.stringify(explicityRequest);
    }
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  getPortalEntity(entityType: string, explicityRequest?: iEntityFilterParams, requestOptions?: any): Observable<any> {
    const reqUrl = entityType +  '/get';
    this.REQUEST_PARAMS.token = this._userService.token;
    let bodyString = JSON.stringify(this.REQUEST_PARAMS);
    if (explicityRequest) {
      explicityRequest.token = this._userService.token;
      bodyString = JSON.stringify(explicityRequest);
    }
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  getEntityInfoWithNoAuth(entityType: string, explicityRequest?: iEntityFilterParams, requestOptions?: any): Observable<any> {
    const reqUrl = 'entity/' + entityType +  '/info';
    let bodyString = JSON.stringify(this.REQUEST_PARAMS);
    if (explicityRequest) {
      bodyString = JSON.stringify(explicityRequest);
    }
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  getEntityInfo(entityType: string, explicityRequest?: iEntityFilterParams, requestOptions?: any): Observable<any> {
    const reqUrl = 'entity/' + entityType +  '/info';
    this.REQUEST_PARAMS.token = this._userService.token;
    let bodyString = JSON.stringify(this.REQUEST_PARAMS);
    if (explicityRequest) {
      explicityRequest.token = this._userService.token;
      bodyString = JSON.stringify(explicityRequest);
    }
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  getImageUrl(imagePart: string): string {
    return environment.apiUrl + '/' + imagePart;
  }

  getUploadedImageUrl(server: string, entityId: number, imageType: string, entityType: string = 'camera'): string {
    return environment.apiUrl + '/entity/' + entityType + '/image/get?' +
      'token=' + this._userService.token + '&' +
      'id=' + entityId + '&' +
      'image_type=' + imageType;
  }

  getGetherEntity(explicityRequest?: iEntityFilterGetherParams, requestOptions?: any): Observable<any> {
    const reqUrl = 'gather/entity/get';
    this.REQUEST_PARAMS.token = this._userService.token;
    let bodyString = JSON.stringify(this.REQUEST_PARAMS);
    if (explicityRequest) {
      explicityRequest.token = this._userService.token;
      bodyString = JSON.stringify(explicityRequest);
    }
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  getherEntityRequest(explicityRequest?: iEntityFilterGetherParams, requestOptions?: any): Observable<any> {
    const reqUrl = 'gather/entity/request';
    this.REQUEST_PARAMS.token = this._userService.token;
    let bodyString = JSON.stringify(this.REQUEST_PARAMS);
    if (explicityRequest) {
      explicityRequest.token = this._userService.token;
      bodyString = JSON.stringify(explicityRequest);
    }
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  /*****************************************************  ACTIONS  ****************************************************/

  countEntity(entityType: string, explicityRequest?: iEntityFilterParams, requestOptions?: any): Observable<any> {
    const reqUrl = 'entity/' + entityType +  '/count';
    this.REQUEST_PARAMS.token = this._userService.token;
    let bodyString = JSON.stringify(this.REQUEST_PARAMS);
    if (explicityRequest) {
      explicityRequest.token = this._userService.token;
      bodyString = JSON.stringify(explicityRequest);
    }
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  countPortalEntity(entityType: string, explicityRequest?: iEntityFilterParams, requestOptions?: any): Observable<any> {
    const reqUrl = entityType +  '/count';
    this.REQUEST_PARAMS.token = this._userService.token;
    let bodyString = JSON.stringify(this.REQUEST_PARAMS);
    if (explicityRequest) {
      explicityRequest.token = this._userService.token;
      bodyString = JSON.stringify(explicityRequest);
    }
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  deleteEntity(entityType: string, value: number[], requestOptions?: any): Observable<any> {
    const reqUrl = 'entity/' + entityType +  '/delete';
    let requestParams: iEntityFilterParams = {
      token: this._userService.token,
      filter_by: [{
        attribute: "id",
        operator: "in",
        value: value
      }]
    };
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  delete(entityType: string, requestParams: iEntityFilterParams, requestOptions?: any): Observable<any> {
    const reqUrl = 'entity/' + entityType +  '/delete';
    requestParams.token =  this._userService.token;
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  deleteCAccount(entityType: string, requestOptions?: any): Observable<any> {
    const reqUrl = entityType + '/delete';
    let requestParams: iEntityFilterParams = {
      token: this._userService.token
    };
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  addEntity(entityType: string, entity: any, requestOptions?: any): Observable<any> {
      const reqUrl = 'entity/' + entityType + '/add';
      let requestParams: iEntityFilterParams = {
        token: this._userService.token,
        values: entity
      };
      const bodyString = JSON.stringify(requestParams);
      return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  resetEntity(entityType: string, entity: number, requestOptions?: any): Observable<any> {
      const reqUrl = entityType + '/reset';
      let requestParams = {
        token: this._userService.token,
        id: entity
      };
      const bodyString = JSON.stringify(requestParams);
      return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  addPortalEntity(entityType: string, entity: any, requestOptions?: any): Observable<any> {
    const reqUrl = entityType +  '/add';
    let requestParams: iEntityFilterParams = {
      token: this._userService.token,
      values: entity
    };
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  update(entityType: string, entity: any, requestOptions?: any): Observable<any> {
    const reqUrl = `${entityType}/update`;
    let requestParams: iEntityFilterParams = { token: this._userService.token, ...entity };
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  updateEntity(entityType: string, entity: any, requestOptions?: any): Observable<any> {
    const reqUrl = 'entity/' + entityType +  '/update';
    let requestParams: iEntityFilterParams = { token: this._userService.token, values: entity };
    let filterBy: iEntityFilterAttribute[] = [];
    if (entity.hasOwnProperty('id')) {
      filterBy = [{ attribute: 'id', operator: '=', value: entity.id }];
    } else if (entity.hasOwnProperty('portal_id')) {
      filterBy = [{ attribute: 'portal_id', operator: '=', value: entity.portal_id }];
    }
    if (filterBy.length) requestParams.filter_by = filterBy;
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  updatePortalEntity(entityType: string, entity: any, requestOptions?: any): Observable<any> {
    const reqUrl = `${entityType}/update`;
    let requestParams: iEntityFilterParams = { token: this._userService.token, values: entity };
    let filterBy: iEntityFilterAttribute[] = [];
    if (entity.hasOwnProperty('id')) {
      filterBy = [{ attribute: 'id', operator: '=', value: entity.id }];
    } else if (entity.hasOwnProperty('portal_id')) {
      filterBy = [{ attribute: 'portal_id', operator: '=', value: entity.portal_id }];
    }
    if (filterBy.length) requestParams.filter_by = filterBy;
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  upsertEntity(entityType: string, entities: any[] | any, requestOptions?: any): Observable<any> {
    const reqUrl = 'entity/' + entityType +  '/upsert';
    let requestParams: any = {
      token: this._userService.token,
      values: entities
    };
    let filterBy: iEntityFilterAttribute[] = [];
    if (entities.hasOwnProperty('id')) {
      filterBy = [{ attribute: 'id', operator: '=', value: entities.id }];
    } else if (entities.hasOwnProperty('portal_id')) {
      filterBy = [{ attribute: 'portal_id', operator: '=', value: entities.portal_id }];
    }
    if (filterBy.length) requestParams.filter_by = filterBy;
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  cloneCMLEntity(entityName: string, entityId: number, selectedEntitys: any, isDuplicate: boolean): Observable<any> {
    const reqUrl = `portal/${entityName}/copy`;
    let requestParams = {
      token: this._userService.token
    };
    requestParams[entityName+'_id'] = entityId;
    if (entityName === 'module') {
      requestParams['new_courses_id'] = selectedEntitys.map(item => item.course_id);
      requestParams['is_duplicate'] = isDuplicate;
    } else {
      requestParams['copy_to'] = selectedEntitys;
    }

    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }

  uploadImage(entityId: number, imageType: string, binaryImage: string, entityType: string = 'camera'): Observable<any> {
    const reqUrl = 'entity/' + entityType +  '/image/upload?' +
      'token=' + this._userService.token + '&' +
      'id=' + entityId + '&' +
      'image_type=' + imageType;
    const defaultHttpOptions = {
      headers: new HttpHeaders({ 'Content-Type':  'image/jpeg' })
    };
    return this.postRequest(reqUrl, binaryImage, defaultHttpOptions);
  }

  toggleUpdateMessage(messageId: number, isEnable: boolean): Observable<any> {
    const reqUrl = '/root_action/system_message/toggle';
    let requestParams = {
      message_id: messageId,
      is_enable: isEnable
    };
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }

  oneClickImport(entityType: string, portal_id: number): Observable<any> {
    const reqUrl = entityType + '/one_click_import';
    let requestParams = { portal_id, token: this._userService.token};
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }

  getAppRequest(reqUrl: string, entity: any, requestOptions?: any): Observable<any> {
    let requestParams: any = {
      token: this._userService.token,
      ...entity
    };
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  /*************************************************  ***********************************************/

  login(loginData: iUser, requestOptions?: any): Observable<any> {
    const reqUrl = 'auth/login';
    const bodyString = JSON.stringify(loginData);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  logout(): Observable<any> {
    const reqUrl = 'auth/logout';
    let requestParams = { token: this._userService.token};
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }

  changePassword(changes: iPasswordChanges, requestOptions?: any): Observable<any> {
    const reqUrl = 'auth/password/change';
    let requestParams: any = changes;
    requestParams.token = this._userService.token;
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  registerSingle(registrationData: iUser, requestOptions?: any): Observable<any> {
    const reqUrl = 'auth/register/single';
    const bodyString = JSON.stringify(registrationData);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
	}

	registerDouble(registrationData: iUser, requestOptions?: any): Observable<any> {
    const reqUrl = 'auth/register/double';
    const bodyString = JSON.stringify(registrationData);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
	}

	registerActivate(registrationData: iUser): Observable<any> {
    const reqUrl = 'auth/register/activate';
    const bodyString = JSON.stringify(registrationData);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }

  registerTrialPortal(trialUserData: iTrialUser): Observable<any> {
    const reqUrl = 'auth/register/content_creator';
    const bodyString = JSON.stringify(trialUserData);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }

  prolongate(token: string, requestOptions?: any): Observable<any> {
    const reqUrl = 'auth/prolongate';
    let requestParams: iEntityFilterParams = { token: token || this._userService.token };
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  activateUser(id: number): Observable<any> {
    const reqUrl = 'auth/activate/customer';
    let requestParams: any = { token: this._userService.token, user_id: id };
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }

  /*************************************************  ***********************************************/

  addApiKey(marketPlaceObj: any, requestOptions?: any): Observable<any> {
    const reqUrl = 'portal/marketplace/api_key/add';
    let requestParams: iEntityFilterParams = {
      token: this._userService.token,
      ...marketPlaceObj
    };
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  generateCopecartCredentials(requestOptions?: any): Observable<any> {
    const reqUrl = '/copecart/credentials/generate';
    let requestParams: iEntityFilterParams = {token: this._userService.token};
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  generateFapiCredentials(requestOptions?: any): Observable<any> {
    const reqUrl = '/fapi/accept_url/generate';
    let requestParams: iEntityFilterParams = {token: this._userService.token};
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  generateZapierApiKey(requestOptions?: any): Observable<any> {
    const reqUrl = '/auth/apikey/generate';
    let requestParams: iEntityFilterParams = {token: this._userService.token};
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  checkDomain(newDomain: string, oldDomain: string, requestOptions?: any): Observable<any> {
    const reqUrl = 'https://' + newDomain + '/entity_api/check_domain';
    let requestParams: any = { old_address: oldDomain };
    const bodyString = JSON.stringify(requestParams);
    return this._http.post(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  saveCustomDomain(customDomain: string, portalId: number, requestOptions?: any): Observable<any> {
    const reqUrl = environment.apiUrl + '/portal/domain/save';
    const requestParams: any = {
      token: this._userService.token,
      new_address: customDomain,
      portal_id: portalId,
    };
    const bodyString = JSON.stringify(requestParams);
    return this._http.post(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  sendHelpMail(message: string, subject: string = 'Message from contact form', requestOptions?: any): Observable<any> {
    const reqUrl = environment.apiUrl + '/helpdesk/email/send';
    let requestParams: any = {
      token: this._userService.token,
      message: message,
      subject: subject
    };
    const bodyString = JSON.stringify(requestParams);
    return this._http.post(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  addToCourseLibrary(type: 'free' | 'preview', courseHash: string, requestOptions?: any): Observable<any> {
    let reqUrl: string = (type === 'free') ? 'customer/course/free/bind' : 'preview/course/add';
    let requestParams: any = { token: this._userService.token, free_access_hash: courseHash };
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  /**
   * Add free course to customer (and create if that customer doesn't exist)
   * @param data
   * @param requestOptions
   * @returns {Observable<any>}
   */
  addFreeCourseToCustomer(data: any, requestOptions?: any): Observable<any> {
    const reqUrl: string = 'customer/course/free/add';
    const bodyString = JSON.stringify(data);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  addFreeCourseToCustomerWithDoubleOptin(data: any, requestOptions?: any): Observable<any> {
    const reqUrl: string = 'lead/course/add';
    const bodyString = JSON.stringify(data);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  abstractAuthorizedPostRequest(uri: string, data: any, requestOptions?: any): Observable<any> {
    const requestParams: any = { token: this._userService.token, ...data };
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(uri, bodyString, requestOptions || this.defaultHttpOptions);
  }

  getPublicLanding(param: string | number, requestOptions?: any): Observable<any> {
    let reqUrl: string = 'public/landing';
    let requestParams: any = {};
    if (typeof param === 'number') {
      requestParams.id = param;
    } else {
      requestParams.permalink = param;
    }
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  getPublicOptinProcess(id: number, requestOptions?: any): Observable<any> {
    let reqUrl: string = 'public/opt_in_process';
    let requestParams: any = { id: id };
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  createLead(data: any, requestOptions?: any): Observable<any> {
    const reqUrl = 'lead/create';
    const bodyString = JSON.stringify(data);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  createActivation(data: any, requestOptions?: any): Observable<any> {
    const reqUrl = 'lead/create_activation';
    const bodyString = JSON.stringify(data);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  activateLead(data: any, requestOptions?: any): Observable<any> {
    const reqUrl = 'lead/activate';
    const bodyString = JSON.stringify(data);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  createOrderByCC(data: any, requestOptions?: any): Observable<any> {
    const reqUrl = 'customer/course/gift';
    const bodyString = JSON.stringify(data);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  /**
   * Login as content creator for admin
   * @param server
   * @param portalId
   * @param requestOptions
   * @returns {Observable<any>}
   */
  loginAsCC(portalId: number, requestOptions?: any): Observable<any> {
    const reqUrl = 'auth/admin/content_creator/login';
    let requestParams: any = { portal_id: portalId, token: this._userService.token };
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  /**
   * Generate new password for customer
   * @param server
   * @param id
   * @param newPassword
   * @param requestOptions
   * @returns {Observable<any>}
   */
  generatePassword(id: number, newPassword?: string, requestOptions?: any): Observable<any> {
    const reqUrl = 'auth/password/customer/generate';
    let requestParams: any = {
      token: this._userService.token,
      id: id,
      new_password: newPassword
    };
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  getPaidStatus(id: number, portalMemberId?: number, requestOptions?: any): Observable<any> {
    const reqUrl = 'portal/course/payment/check';
    let requestParams: any = {
      token: this._userService.token,
      portal_member_id: portalMemberId || this._userService.client.portal_member.id,
      id: id
    };
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  setSmtpSettings(entity: any, requestOptions?: any): Observable<any> {
    const reqUrl = 'portal/settings/smtp/set';
    let requestParams: any = {
      token: this._userService.token,
      ...entity
    };
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  checkEmailsTemplates(entity: any, requestOptions?: any): Observable<any> {
    const reqUrl = 'portal/settings/email_template/check';
    let requestParams: any = {
      token: this._userService.token,
      ...entity
    };
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  getCustomerCourseAndModulesProgress(courseIds: Array<number>, portalMemberId: number): Observable<any> {
    const reqUrl = 'portal/course/progress/get';
    const requestParams: any = {
      token: this._userService.token,
      customer_id: portalMemberId,
      course_ids: courseIds
    };
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }

  getLessonAndModulesAccess(customerId: number, courseIds: Array<number>, limit: number = 100): Observable<any> {
    const reqUrl = 'portal/course/access/check';
    const requestParams: any = {
      token: this._userService.token,
      customer_id: customerId,
      course_ids: courseIds,
      limit: limit
    };
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }

  getCoursesModulesAndLessonsCount(courseIds: Array<number>): Observable<any> {
    const reqUrl = 'portal/course/parts/count';
    const requestParams: any = {
      token: this._userService.token,
      course_ids: courseIds
    };
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }
  // Video connector api //
  getVideoAnimatedPreview(fileHandle: string | number): Observable<any> {
    const reqUrl = 'video/animated_preview/get';
    const requestParams: any = {
      token: this._userService.token,
      file_handle: fileHandle
    };
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }

  setVideoStaticPreview(videoFileHandle: string, previewFileId: string): Observable<any> {
    const reqUrl = 'video/preview/set/custom';
    const requestParams: any = {
      token: this._userService.token,
      video_id: videoFileHandle,
      preview_file_id: previewFileId
    };
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }

  resetVideoStaticPreview(videoFileHandle: string, previewFrameSecond?: number): Observable<any> {
    const reqUrl = 'video/preview/set/default';
    const requestParams: any = {
      token: this._userService.token,
      video_id: videoFileHandle,
      preview_frame_second: previewFrameSecond
    };
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }
  // ******************** //

  copyToTheEntity(copyEntityId: number, newEntitysId: Array<number>, entityName: string): Observable<any> {
    const reqUrl = 'portal/' + entityName + '/copy';
    let requestParams: any = {
      token: this._userService.token
    };
    if (entityName === 'module') {
      requestParams.module_id = copyEntityId;
      requestParams.new_courses_id = newEntitysId;
    } else {
      requestParams.lesson_id = copyEntityId;
      requestParams.new_modules_id = newEntitysId;
    }
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }

  setAccessToUser(entityType: string, values: any): Observable<any> {
    const reqUrl = 'portal/' + entityType + '/access/update';
    const requestParams: any = {
      token: this._userService.token,
      values: values
    };
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }

  setCourseActivity(courseId: number, courseActive: boolean) {
    const reqUrl = 'portal/course/activation';
    const requestParams = {
      token: this._userService.token,
      course_id: courseId,
      is_active: courseActive
    };
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }

  /************************************************* EMAIL TEST ***********************************************/

  emailTest(entityType: string, entity: any, requestOptions?: any): Observable<any> {
    const reqUrl: string = `lead/${entityType}/email/test`;
    let requestParams: any = {
      token: this._userService.token,
      ...entity
    };
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  /************************************************* ONE CLICK AFFILATE ***********************************************/

  acceptAffiliate(userPortalId: number, requestOptions?: any): Observable<any> {
    const reqUrl: string = `affiliate/accept`;
    let requestParams: any = {
      token: this._userService.token,
      portal_id: userPortalId
    };
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, requestOptions || this.defaultHttpOptions);
  }

  importWhiteLabelCourse(userPortalId: number, courseId: number): Observable<any> {
    const reqUrl: string = `portal/course/import`;
    let requestParams: any = {
      token: this._userService.token,
      portal_id: userPortalId,
      course_id: courseId
    };
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }

  importWhiteLabelLanding(userPortalId: number, landingId: number): Observable<any> {
    const reqUrl: string = `portal/landing/import`;
    let requestParams: any = {
      token: this._userService.token,
      portal_id: userPortalId,
      landing_id: landingId
    };
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }

  /************************************************* QUIZ ***********************************************/

  startNewQuizPass(lessonId: number): Observable<any> {
    const reqUrl = 'quiz/start';
    const body = {
      lesson_id: lessonId,
      token: this._userService.token
    }
    const bodyString = JSON.stringify(body);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }

  getQuizVerionByTimestamp(explicityRequest: iEntityFilterParams): Observable<any> {
    const reqUrl = '/history/module_lesson_content_block/get_by_timestamp';
    let bodyString;
    if (explicityRequest) {
      explicityRequest.token = this._userService.token;
      bodyString = JSON.stringify(explicityRequest);
    }
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }

  getQuizAccessByCourseIds(userId: number, courseId: number): Observable<any> {
    const reqUrl = 'portal/quiz/access/check';
    const body = {
      token: this._userService.token,
      customer_id: userId,
      course_id: courseId
    }
    const bodyString = JSON.stringify(body);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }

  getExistQuizPassing(filters?: any): Observable<any> {
    const reqUrl = 'entity/quiz_assessment/get';
    const body = {
      token: this._userService.token,
      ...filters
    }
    const bodyString = JSON.stringify(body);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }

  getQuizQuestion(assessmentId: number): Observable<any> {
    const reqUrl = 'quiz/questions/next';
    const body = {
      assessment_id: assessmentId,
      token: this._userService.token
    }
    const bodyString = JSON.stringify(body);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }

  getQuizResults(assessmentId: number): Observable<any> {
    const reqUrl = 'quiz/results/get';
    const body = {
      assessment_id: assessmentId,
      token: this._userService.token
    }
    const bodyString = JSON.stringify(body);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }

  saveQuizAnswers(assessmentId: number, answers: Object): Observable<any> {
    const reqUrl = 'quiz/results/add';
    const body = {
      assessment_id: assessmentId,
      customer_answers: [answers],
      token: this._userService.token
    }
    const bodyString = JSON.stringify(body);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }

  getQuizAssesments(filters?: any): Observable<any> {
    const reqUrl = 'quiz/assessments/get';
    const body = {
      token: this._userService.token,
      ...filters
    }
    const bodyString = JSON.stringify(body);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }

  getQuizAssesmentsByLessons(filters?: any): Observable<any> {
    const reqUrl = 'customer/quiz/assessment/get';
    const body = {
      token: this._userService.token,
      ...filters
    }
    const bodyString = JSON.stringify(body);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }

  /************************************************* COMMUNITY ***********************************************/

  getCommunityTypes(): Observable<any> {
    const reqUrl = 'entity/community_element_type/get';
    return this.getRequest(reqUrl);
  }

  getCommunitySettings(): Observable<any> {
    const reqUrl = 'entity/community_settings/info'
    const requestParams: any = {
      token: this._userService.token
    };
    let bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }

  getCommunityDisplayNameSettings(): Observable<any> {
    const reqUrl = 'community_settings/display_name_setting_code/get?token=' + this._userService.token;
    const requestParams: any = {
      token: this._userService.token
    };
    let bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }

  updateCommunitySettings(data: any): Observable<any> {
    const reqUrl = 'entity/community_settings/update';
    const requestParams: any = {
      values: data,
      token: this._userService.token
    }
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }

  updateBindedCourseList(communityId: number, courseIds: number[]): Observable<iCoursesToCommunity> {
    const reqUrl = 'courses_to_community/bind';
    const requestParams: any = {
      community_id: communityId,
      course_ids: courseIds,
      token: this._userService.token
    }
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }

  /************************************************* TRIAL USER ***********************************************/

  updateTrialUserTime(portal_id: number, new_date: number): Observable<any> {
    const reqUrl = 'admin/trial_portal/extend';
    const requestParams: any = {
      portal_id: portal_id,
      new_ended_at: new_date,
      token: this._userService.token
    };
    let bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }

  /************************************************* TRIAL USER ***********************************************/

	contentCreatorVerify(verificationData: iUser): Observable<any> {
    const reqUrl = 'auth/register/verify';
    const bodyString = JSON.stringify(verificationData);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }

  resendVerificationEmail(portalData: {portal_id: number, token: string}): Observable<any> {
    const reqUrl = 'auth/verification/email/send';
    const bodyString = JSON.stringify(portalData);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }

  rootVerifyContentCreator(portalData: {portal_id: number, token: string}): Observable<any> {
    const reqUrl = 'auth/verify/content_creator';
    const bodyString = JSON.stringify(portalData);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }

  /******************************************* RECALCULATE COURSE ACCESS ***************************************/
  recalculateCourseAccess(courseId: number): Observable<any> {
    const reqUrl = 'portal/course/access/recalculate';
    const requestParams: any = {
      course_id: courseId,
      token: this._userService.token
    }
    const bodyString = JSON.stringify(requestParams);
    return this.postRequest(reqUrl, bodyString, this.defaultHttpOptions);
  }
}
