import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccessGuard } from './guards/access.guard';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { SupportGuard } from './guards/support.guard';
import { SignupGuard } from './guards/signup.guard';
import { UserOnboardingGuard } from './guards/user-onboarding.guard';
import { TrialEndGuard } from '@coreguards/trial-end.guard';
import { ContentCreatorGuard } from '@coreguards/content-creator.guard';
import { CustomerDesignGuard } from './guards/customer-design.guard';
import { CommunityLinkGuard } from '@coreguards/community-link.guard';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('app/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AccessGuard, UserOnboardingGuard, ContentCreatorGuard, CommunityLinkGuard]
  },
  {
    path: 'customer-new',
    redirectTo: 'customer'
  },
  {
    path: 'customer',
    loadChildren: () => import('app/customer/customer.module').then(m => m.CustomerNewModule),
    canActivate: [AccessGuard, CommunityLinkGuard]
  },
  {
    path: 'registration',
    loadChildren: () => import('app/_core/components/user/registration/registration.module').then(m => m.RegistrationModule)
  },
  {
    path: 'support',
    loadChildren: () => import('app/_core/components/support/support.module').then((m) => m.SupportModule),
    canActivate: [SupportGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('app/_core/components/user/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'features',
    loadChildren: () => import('app/_core/components/user/feature/feature.module').then(m => m.FeatureModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('app/signup-second-step/signup-second-step.module').then(m => m.SignupSecondStepModule),
    canActivate: [SignupGuard]
  },
  {
    path: 'activate',
    loadChildren: () => import('@corecomponents/user/activation/activation.module').then(m => m.ActivateAndCreatePasswordModule)
  },
  {
    path: 'forgotpass',
    loadChildren: () => import('app/_core/components/user/forgotpass/forgotpass.module').then(m => m.ForgotpassModule)
  },
  {
    path: 'imprint',
    loadChildren: () => import('app/_core/components/user/static-page/user-static-page.module').then(m => m.UserStaticPageModule)
  },
  {
    path: 'user-onboarding',
    loadChildren: () => import('./user-onboarding/user-onboarding.module').then(m => m.UserOnboardingModule),
    canActivate: [AccessGuard]
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('app/_core/components/user/static-page/user-static-page.module').then(m => m.UserStaticPageModule)
  },
  {
    path: 'terms-conditions',
    loadChildren: () => import('app/_core/components/user/static-page/user-static-page.module').then(m => m.UserStaticPageModule)
  },
  {
    path: 'landings',
    loadChildren: () => import('app/_core/components/landing-pages/landing-pages.module').then(m => m.LandingPagesModule)
  },
  {
    path: 'lp',
    loadChildren: () => import('app/_core/landing-new/landing-new.module').then(m => m.LandingNewModule)
  },
  {
    path: 'confirmation',
    loadChildren: () => import('app/_core/components/user-confirmation/user-confirmation.module').then(m => m.UserConfirmationModule)
  },
  {
    path: 'partner',
    loadChildren: () => import('app/_core/components/partner-detail/partner-detail.module').then(m => m.PartnerDetailModule)
  },
  {
    path: 'trial-period-end',
    loadChildren: () => import('app/_core/components/trial-period-end/trial-period-end.module').then(m => m.TrialPeriodEndModule),
    canActivate: [AccessGuard, TrialEndGuard]
  },
  {
    path: 'community',
    loadChildren: () => import('@admincommunity/community.module').then(m => m.CommunityModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: '**',
    component: NotfoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false})],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
