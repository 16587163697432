import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UserService } from './user/user.service';
import { EntityService } from './entity.service';
import { LoginStartupService } from './login-startup.service';
import { CustomerService } from '@customercustomer.service';

declare var fbq: any;

@Injectable({
  providedIn: 'root'
})
export class FacebookPixelService {

  private _apiKey: string;
  private _initialized: boolean;

 constructor(
    private _router: Router,
    private _userService: UserService,
    private _entityService: EntityService,
    private _customerService: CustomerService,
    private _loginStartupService: LoginStartupService) {}

  public async start() {
    this._router.events.subscribe(async(event) => {
      if (event instanceof NavigationEnd) {
        const role = this._userService.getRole();
        if (!event.urlAfterRedirects.includes('/admin') && !event.urlAfterRedirects.includes('/signup')  && role !== 'hidden_customer' ) {
          const ableToTrack = await this.prepare();
          if (ableToTrack) {
            if (role === 'customer') {
              this._customerService.getCustomerInfo().subscribe(response => {
                if (response.gdpr_acceptation.is_statistics || (response.gdpr_acceptation.is_statistics === undefined && response.gdpr_acceptation.is_accept)) {
                  this.track();
                }
              });
            } else {
              this.track();
            }
          }
        }
      }
    });
  }

  private prepare() {
    return new Promise<boolean>(async (resolve) => {
      if (this._initialized) {
        resolve(true);
      } else {
        const settingsStatus = await this.getSettings().toPromise();
        if (!settingsStatus) {
          resolve(false);
          return;
        }

        const scriptAttached = await this.appendFBQScript();
        if (!scriptAttached) {
          console.warn(`Failed to attach FB Pixel; Api Key: ${this._apiKey}`);
        }
        this._initialized = scriptAttached;
        resolve(scriptAttached);
      }

    });
  }

  private track(trackingType: string = 'PageView') {
    fbq('track', trackingType);
  }


  private getSettings() {
    return this._entityService.getEntity('settings_analytics').filter(
      (result: any) => {
        const data = result[0];
        this._apiKey = data?.facebook_pixel;
        return Boolean(this._apiKey && data?.facebook_pixel_is_active);
      });
  }

  private appendFBQScript() {
    try {
      (function (f: any, b, e, v, n, t, s) {
          if (f.fbq) { return; } n = f.fbq = function () {
              n.callMethod ?
                  n.callMethod.apply(n, arguments) : n.queue.push(arguments);
          }; if (!f._fbq) { f._fbq = n; }
          n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = []; t = b.createElement(e); t.async = !0;
          t.src = v; s = b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t, s);
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js?ngsw-bypass=true');
      fbq.disablePushState = true;
      fbq.allowDuplicatePageViews = true;
      fbq('init', this._apiKey);
    } catch (ex) {
      console.error(ex);
    }

    return this.checkIfFBQLoaded();

  }

  // We need this check, because although script may be attached, there is still no fbq var
  checkIfFBQLoaded = () => {
    return new Promise<boolean>((resolve) => {
      const allowedAttempts = 30;
      let attemptsMade = 0;
      check();

      function check() {
        setInterval(() => {
          if (typeof fbq === 'function' || attemptsMade >= allowedAttempts) {
            resolve(typeof fbq === 'function');
          } else {
            check();
          }
          attemptsMade++;
        }, 500);
      }
    });
  }


}
